<script setup >
import {MainLayoutStore} from "./main-layout-store";
import {useRoute} from "vue-router";
import {EnvStore} from "~/common-store/env-store";
import LangText from "~/components/lang/LangText";
import Link from "~/components/link/Link";
import {ChevronRightIcon} from "@heroicons/vue/20/solid";
import {DisclosureButton, Disclosure, DisclosurePanel} from '@headlessui/vue';
import {GlobalPlatformInfoStore} from "~/store/global-platform-info-store";

const conf = EnvStore().config;

const mainLayoutStore = MainLayoutStore();
const route = useRoute();
const globalPlatformInfoStore = GlobalPlatformInfoStore();

</script>

<template>
  <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
    <div class="flex h-16 shrink-0 items-center">
      <div class="text-[35px] font-bold">
        {{ globalPlatformInfoStore.platformLogoFullText }}
      </div>
    </div>
    <nav class="flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" class="-mx-2 space-y-1">
            <li
                v-for="(menu,index) in mainLayoutStore.menus"
                :key="index"
            >
              <Link
                  v-if="!menu.children"
                  :to="menu.href"
                  :external="menu.direct"
                  :class="[route.path === menu.href ? 'bg-gray-50 text-indigo-600' : 'text-gray-700', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:text-indigo-600 hover:bg-gray-50']"
              >
                <component
                    :is="menu.icon"
                    :class="[route.path === menu.href ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                    aria-hidden="true"
                />
                <div class="ml-6"></div> <LangText popoverPosition="right" :msgKey="menu.name"/>
              </Link>
              <div v-else>
                <Disclosure
                    as="div"
                    v-if="mainLayoutStore.parentMenuOpen(menu)"
                    v-slot="{ open }"
                    :defaultOpen="mainLayoutStore.parentMenuOpen(menu)"
                >
                  <DisclosureButton
                      :class="['hover:bg-gray-50', 'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700']">
                    <ChevronRightIcon
                        :class="[
                          open ?
                      'rotate-90 text-gray-500' :
                       'text-gray-400',
                       'h-5 w-5 shrink-0',
                       ]"
                        aria-hidden="true"
                    />
                    <LangText popoverPosition="right" :msgKey="menu.name"/>
                  </DisclosureButton>
                  <DisclosurePanel as="ul" class="mt-1 px-2">
                    <Link
                        v-for="(subMenu,subMenuIndex) in menu.children" :key="subMenuIndex"
                        :to="subMenu.href"
                        :external="subMenu.direct"
                        :class="[route.path === subMenu.href || mainLayoutStore.currentSubMenuName === subMenu.name ? 'bg-gray-50 text-indigo-600' : 'text-gray-700', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:text-indigo-600 hover:bg-gray-50']"
                    >
                      <component
                          :is="subMenu.icon"
                          :class="[route.path === subMenu.href || mainLayoutStore.currentSubMenuName === subMenu.name ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                          aria-hidden="true"
                      />
                      <div class="ml-6"></div> <LangText popoverPosition="right" :msgKey="subMenu.name"/>
                    </Link>
                  </DisclosurePanel>
                </Disclosure>
                <Disclosure
                    as="div"
                    v-else
                    v-slot="{ open }"
                    :defaultOpen="false"
                >
                  <DisclosureButton
                      :class="['hover:bg-gray-50', 'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700']">
                    <ChevronRightIcon
                        :class="[
                          open ?
                      'rotate-90 text-gray-500' :
                       'text-gray-400',
                       'h-5 w-5 shrink-0',
                       ]"
                        aria-hidden="true"
                    />
                    <LangText popoverPosition="right" :msgKey="menu.name"/>
                  </DisclosureButton>
                  <DisclosurePanel as="ul" class="mt-1 px-2">
                    <Link
                        v-for="(subMenu,subMenuIndex) in menu.children" :key="subMenuIndex"
                        :to="subMenu.href"
                        :external="subMenu.direct"
                        :class="[route.path === subMenu.href || mainLayoutStore.currentSubMenuName === subMenu.name ? 'bg-gray-50 text-indigo-600' : 'text-gray-700', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:text-indigo-600 hover:bg-gray-50']"
                    >
                      <component
                          :is="subMenu.icon"
                          :class="[route.path === subMenu.href || mainLayoutStore.currentSubMenuName === subMenu.name ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                          aria-hidden="true"
                      />
                      <div class="ml-6"></div><LangText popoverPosition="right" :msgKey="subMenu.name"/>
                    </Link>
                  </DisclosurePanel>
                </Disclosure>
              </div>
            </li>
          </ul>
        </li>
        <li class="mt-auto">
          <div class="text-gray-500">
            <div class="flex">
              <LangText msgKey="ROrDhZZzQ7"/>
              : {{ conf.Version }} | {{ conf.ENV }}
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>
